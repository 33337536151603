@import "fancybox-master/dist/jquery.fancybox";

.fancybox-active {
  min-height: 100%;
}

[data-theme="light"] {
  .fancybox-container  {
    color: var(--kt-text-muted);
  }

  .fancybox-content {
    background: var(--kt-app-bg-color);
  }
}

[data-theme="dark"] {
  .fancybox-container  {
    color: var(--kt-text-muted);
  }

  .fancybox-content {
    background: var(--kt-app-bg-color);
  }
}
